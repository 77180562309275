import {
  collection,
  onSnapshot,
  query,
  orderBy,
  where,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { ReactNode, useEffect, useState } from "react";
import { userState } from "../../common/recoilStateDefs";
import { useRecoilValue } from "recoil";
import { startCase, camelCase } from "lodash";
import SimpleTable from "../../common/components/SimpleTable/SimpleTable";
import DetailsModal from "../../common/components/DetailsModal/DetailsModal";

type ArbitraryInventoryData = {
  [key: string]: any;
};

export default function InventoryTable() {
  const [data, setData] = useState<ArbitraryInventoryData[] | undefined>(
    undefined,
  );
  const [modalData, setModalData] = useState<
    { title: string; content: ReactNode } | undefined
  >();
  const user = useRecoilValue(userState);

  useEffect(() => {
    const collectionRef = collection(db, "inventory");
    const q = query(
      collectionRef,
      where("supplierId", "==", user?.supplier?.id),
      orderBy("updatedAt", "desc"),
    );
    const unsubscribe = onSnapshot(q, (snap) => {
      setData(snap.docs.map((d) => d.data() as ArbitraryInventoryData));
    });

    return () => unsubscribe();
  }, []);

  const formatColumnTitle = (s: string) => startCase(camelCase(s));

  const formatColumnValue = (d: any, colTitle: string): string | ReactNode => {
    console.log("formatColumnValue", colTitle, d);
    if (d.toDate) {
      return (d.toDate() as Date).toDateString();
    }
    if (typeof d === "object" && colTitle === "material") {
      console.log("material", d);
      return (
        <>
          <span key={JSON.stringify(d.dbMaterials)}>
            {d.dbMaterials.join(", ")}
          </span>
        </>
      );
    }
    if (typeof d === "object") {
      return (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setModalData({
              title: formatColumnTitle(colTitle),
              content: <pre>{JSON.stringify(d, null, 2)}</pre>,
            });
          }}
          className="hover:text-teal-500 border-b border-dashed"
        >
          [show]
        </a>
      );
    }
    if (d.toString) return d.toString();
    return d as string;
  };

  if (data === undefined) {
    return <div>Loading</div>;
  }

  if (data.length === 0) {
    return <>No inventory yet</>;
  }

  const columnTitlesRaw = new Set<string>();
  data.forEach((d) => Object.keys(d).forEach((k) => columnTitlesRaw.add(k)));

  const columnTitles = Array.from(columnTitlesRaw)
    .filter((cId) => cId !== "supplierId")
    .map(formatColumnTitle)
    .sort();
  return (
    <>
      <SimpleTable
        columnTitles={columnTitles}
        data={data.map((aid) =>
          Object.fromEntries(
            Object.entries(aid).map(([ct, val]) => [
              formatColumnTitle(ct),
              formatColumnValue(val, ct),
            ]),
          ),
        )}
      />
      {modalData && (
        <DetailsModal {...modalData} onClose={() => setModalData(undefined)} />
      )}
    </>
  );
}
