import { useRecoilValue } from "recoil";
import { userState } from "../../common/recoilStateDefs";
import { sendAuthenticatedRequest } from "../../common/functions";
import LoadableSetting from "./LoadableSetting";
import UpdateableSetting from "./UpdateableSetting";
import { db } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";
import { MouseEvent, useState } from "react";

export default function OrganizationSettings() {
  const user = useRecoilValue(userState);

  const inventoryApiTokenLoadFn = () => {
    const requestUrl = `${process.env.REACT_APP_USER_API_BASE_URL}/suppliers/${user?.supplier?.id}/inventoryApiToken`;
    const requestOptions = { method: "GET" };

    return sendAuthenticatedRequest(requestUrl, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        if (!data || !data.inventoryApiToken) {
          throw new Error("Failed to retrieve API token from the database");
        }
        return data.inventoryApiToken as string;
      });
  };

  const loadSecretsDoc = () => {
    return getDoc(doc(db, `supplierSecretData/${user?.supplier?.id}`)).then(
      (d) => d.data(),
    );
  };

  const updateErpPullData = (d: {
    odooHostname?: string;
    odooUsername?: string;
    odooPassword?: string;
    odooDatabase?: string;
    sapApiHostname?: string;
    sapApiKey?: string;
  }) => {
    return sendAuthenticatedRequest(
      `${process.env.REACT_APP_USER_API_BASE_URL}/suppliers/${user?.supplier?.id}/erpPullCredentials`,
      { method: "POST", jsonBody: d },
    );
  };
  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Company Information
        </h2>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Company Name
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {user?.supplier?.businessName || "Unknown"}
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Address
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {user?.supplier?.address || "Unknown"}
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Inventory
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Settings related to managing your organization's inventory and
          uploading it to Gaias.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <LoadableSetting
            label="Inventory API Token"
            getValue={inventoryApiTokenLoadFn}
            loadButtonText="Generate"
          >
            <p className="mt-1 text-xs leading-6 text-gray-700">
              You will need this token to connect your ERP system to Gaias. Note
              that the token will be visible{" "}
              <span className="font-bold">only once</span> after generation. If
              you regenerate the token later, the old token will stop working.
            </p>
          </LoadableSetting>
        </dl>
      </div>
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Odoo Credentials (Pull)
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Specify the Odoo credentials that Gaias can use to retrieve your
          inventory.
        </p>
        <dl>
          <UpdateableSetting
            label="Odoo Host URL"
            getValue={() => loadSecretsDoc().then((d) => d?.odooHostname)}
            setValue={(v) => updateErpPullData({ odooHostname: v })}
          />
          <UpdateableSetting
            label="Odoo Database Name"
            getValue={() => loadSecretsDoc().then((d) => d?.odooDatabase)}
            setValue={(v) => updateErpPullData({ odooDatabase: v })}
          />
          <UpdateableSetting
            label="Odoo Username"
            getValue={() => loadSecretsDoc().then((d) => d?.odooUsername)}
            setValue={(v) => updateErpPullData({ odooUsername: v })}
          />
          <UpdateableSetting
            label="Odoo Password"
            getValue={() => Promise.resolve("")}
            setValue={(v) => updateErpPullData({ odooPassword: v })}
            isSecret={true}
          />
        </dl>
        <div className="mt-10">
          <RetrieveFromErpButton erp="odoo" />
        </div>
      </div>
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          SAP Credentials (Pull)
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Specify the SAP API credentials that Gaias can use to retrieve your
          inventory.
        </p>
        <dl>
          <UpdateableSetting
            label="SAP API Host URL"
            getValue={() => loadSecretsDoc().then((d) => d?.sapApiHostname)}
            setValue={(v) => updateErpPullData({ sapApiHostname: v })}
          />
          <UpdateableSetting
            label="SAP API Key"
            getValue={() => loadSecretsDoc().then((d) => d?.sapApiKey)}
            setValue={(v) => updateErpPullData({ sapApiKey: v })}
            isSecret={true}
          />
        </dl>
        <div className="mt-10">
          <RetrieveFromErpButton erp="sap" />
        </div>
      </div>
    </div>
  );
}

function RetrieveFromErpButton({ erp }: { erp: "odoo" | "sap" }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const user = useRecoilValue(userState);

  const erpName = { odoo: "Odoo", sap: "SAP" }[erp];
  const erpEndpoint = { odoo: "syncOdoo", sap: "syncSap" }[erp];
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setError(false);
    sendAuthenticatedRequest(
      `${process.env.REACT_APP_INVENTORY_API_BASE_URL}/suppliers/${user?.supplier?.id}/${erpEndpoint}`,
      { method: "POST" },
    )
      .then((resp) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <>
      {" "}
      <button
        type="button"
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        disabled={loading}
        onClick={handleClick}
      >
        Retrieve inventory data from {erpName}
      </button>
      {loading && <span className="text-sm ml-3">working...</span>}
      {error && <span className="text-sm ml-3 text-red-700">Error</span>}
      {success && <span className="text-sm ml-3 text-green-700">Ok</span>}
    </>
  );
}
